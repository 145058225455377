<template>
    <div>
        <div id=footer-wrap>
            <footer id=footer>
                <div class=content>
                    <div class="clearfix widget-area widget-area-3" data-num-widgets=3 id=footer-widget-area role=complementary>
                        <aside class="widget om-widget-hcard" id=hcard-2>
                            <h3><a href="/contact" title=Contact>Contact</a><span></span></h3>
                            <div class="vcard hcard-container clearfix text">
                                <h2><a class="url fn org" href="/" title="Brasserie Den Anker">Brasserie Den Anker</a></h2>
                                <div class="hcard-wrap clearfix">
                                    <p class=adr><span class=icon>Adres</span><span><span class=street-address>Polderstraat 1A</span><br><span class=postal-code>2260 </span>
                                        <span class=locality>Westerlo</span>
                                        <br>
                                    </span>
                                    </p>
                                    <p class=tel><span class=icon>Tel.</span><a href=tel:+3214728352><span
                                            class=value>+32 14 72 83 52</span></a><span class=type>work</span><span class=type>pref</span></p>
                                    <p class=email-wrap><span class=icon>E-mail</span><a class=email href="kennith@brasseriedenanker.be"><span class=value>kennith@brasseriedenanker.be</span></a>
                                        <span class=type>pref</span>
                                    </p>
<!--                                    <p class=vat><span class=icon>BTW</span><span>BE00000000</span></p>-->
                                </div>
                            </div>
                        </aside>
                        <aside class="widget widget_text" id=text-2>
                            <h3>Openingsuren<span></span></h3>
                            <div class=textwidget>
                                <p>Wij zijn geopend van 11.00 tot 23.00 uur</p>
                                <p>Woensdag van 08.00 tot 23.00 uur</p>
                                <p style="color:#a09174">Maandag & Dinsdag gesloten.</p>
                                <p>&nbsp;</p>
                            </div>
                        </aside>
                        <aside class="widget om-widget-svg" id=svg-2>
                            <h3>Brasserie Den Anker<span></span></h3>
                            <div>
                                <figure>
                                    <a href="#" title="Brasserie Den Anker">
                                        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                             width="1772.000000pt" height="1772.000000pt" viewBox="0 0 1772.000000 1772.000000"
                                             preserveAspectRatio="xMidYMid meet">
                                            <g transform="translate(0.000000,1772.000000) scale(0.100000,-0.100000)" stroke="none">
                                                <path d="M8500 16514 c-30 -2 -127 -8 -215 -14 -761 -50 -1565 -232 -2285
                                    -517 -2131 -846 -3785 -2610 -4489 -4786 -234 -726 -352 -1439 -368 -2227 -13
                                    -688 60 -1340 223 -1995 71 -285 101 -380 130 -413 l26 -30 39 17 c21 9 39 21
                                    39 26 0 6 -14 57 -30 115 -257 887 -345 1855 -254 2775 181 1827 1033 3522
                                    2389 4752 1114 1011 2502 1656 3997 1857 356 48 554 60 978 60 424 0 622 -12
                                    978 -60 1893 -254 3608 -1223 4799 -2709 638 -797 1113 -1738 1368 -2710 31
                                    -115 60 -226 65 -246 7 -27 25 -48 63 -77 28 -21 65 -51 80 -66 l29 -27 71 20
                                    c40 11 90 22 113 26 26 4 54 19 82 43 l42 37 -30 130 c-122 537 -283 1021
                                    -497 1493 -592 1310 -1534 2425 -2728 3227 -1113 748 -2354 1176 -3720 1285
                                    -131 11 -792 21 -895 14z"/>
                                                <path d="M8340 14954 c-1051 -68 -2046 -365 -2931 -874 -179 -103 -503 -317
                                    -669 -441 -1397 -1048 -2326 -2584 -2600 -4299 -61 -383 -85 -743 -77 -1170
                                    11 -615 82 -1102 249 -1705 22 -82 42 -151 43 -152 1 -2 25 4 54 13 42 12 51
                                    19 46 32 -17 44 -89 324 -124 486 -100 457 -144 871 -144 1346 0 413 21 669
                                    84 1060 264 1626 1160 3102 2484 4093 1469 1100 3311 1518 5114 1161 997 -198
                                    1974 -655 2771 -1298 359 -289 803 -744 1057 -1082 12 -16 48 -64 80 -106 238
                                    -316 515 -791 696 -1193 108 -242 270 -687 307 -842 11 -49 17 -57 91 -113
                                    l79 -60 57 19 c32 11 74 23 93 26 40 8 100 60 100 88 0 28 -93 345 -151 513
                                    -214 625 -506 1189 -898 1734 -1002 1396 -2533 2363 -4226 2669 -237 43 -466
                                    71 -730 91 -108 8 -742 11 -855 4z"/>
                                                <path d="M16843 9595 c-141 -43 -259 -179 -361 -411 -67 -153 -122 -335 -151
                                    -501 l-28 -158 -17 55 c-58 197 -69 268 -75 508 l-6 234 -28 24 c-19 16 -40
                                    24 -66 24 -68 0 -118 -58 -147 -170 -14 -54 -14 -76 -4 -161 l12 -98 -102
                                    -173 c-244 -414 -341 -552 -486 -695 -142 -140 -290 -219 -430 -230 -76 -6
                                    -129 6 -177 39 -30 21 -87 112 -87 139 0 9 50 85 111 170 258 359 348 553 336
                                    729 -12 175 -111 305 -249 325 -38 6 -57 3 -100 -17 -112 -52 -196 -145 -273
                                    -303 -117 -238 -152 -495 -104 -775 l21 -122 -34 -35 c-104 -109 -310 -228
                                    -473 -275 -138 -39 -342 -25 -562 38 -119 34 -173 55 -173 67 0 20 110 218
                                    183 330 40 61 103 149 140 195 86 107 118 170 125 248 5 73 -7 100 -53 120
                                    -57 23 -75 18 -130 -38 -112 -117 -309 -503 -489 -962 -97 -245 -105 -262
                                    -116 -250 -17 19 -22 185 -29 904 -8 823 -6 799 -73 855 -37 31 -75 32 -118 3
                                    -71 -49 -91 -105 -78 -215 10 -89 35 -373 48 -546 9 -126 9 -151 -4 -175 -8
                                    -15 -31 -86 -50 -158 -96 -361 -244 -644 -440 -840 -80 -79 -117 -108 -177
                                    -138 -103 -51 -141 -51 -185 -4 -43 48 -56 94 -110 403 -69 395 -68 391 -122
                                    428 -61 41 -77 42 -148 7 -77 -38 -142 -102 -224 -221 -79 -116 -142 -243
                                    -239 -487 -120 -299 -180 -413 -195 -365 -7 25 -23 211 -31 363 -15 309 -57
                                    470 -131 505 -71 33 -128 1 -158 -88 -21 -63 -21 -65 14 -209 38 -156 73 -379
                                    100 -628 24 -223 41 -318 65 -354 22 -33 71 -48 125 -40 66 11 95 44 136 159
                                    19 55 64 165 99 245 35 80 88 206 118 280 31 74 72 173 92 220 73 170 151 290
                                    189 290 39 0 80 -111 130 -350 39 -185 53 -230 89 -286 119 -187 352 -176 602
                                    27 158 128 345 424 447 706 18 51 35 93 38 93 3 0 6 -26 8 -57 13 -296 53
                                    -624 89 -733 31 -94 101 -150 188 -150 58 0 60 4 80 202 24 232 59 397 101
                                    481 13 25 18 27 57 21 23 -4 118 -31 211 -60 213 -68 299 -86 407 -86 151 0
                                    310 55 455 158 34 24 106 90 161 148 l99 105 24 -47 c14 -25 49 -72 78 -104
                                    123 -133 308 -166 520 -94 184 62 424 288 615 582 81 123 194 330 244 444 l38
                                    86 41 -166 c41 -162 165 -520 201 -583 43 -73 148 -102 206 -57 34 27 37 67
                                    20 235 -23 214 3 430 77 655 64 193 156 368 239 453 67 69 102 76 190 37 93
                                    -40 142 -40 183 1 28 27 30 34 24 76 -21 155 -183 248 -343 198z m-1972 -618
                                    c69 -53 73 -174 12 -322 -30 -71 -113 -215 -197 -340 l-60 -90 -9 35 c-5 19
                                    -7 105 -4 190 5 174 32 299 86 414 49 103 123 151 172 113z"/>
                                                <path d="M8823 8190 c-46 -19 -88 -147 -124 -383 -34 -224 -92 -426 -196 -682
                                    -88 -218 -163 -417 -229 -609 -107 -311 -176 -492 -229 -602 -52 -107 -56
                                    -113 -109 -142 -61 -35 -75 -61 -52 -97 37 -57 150 -80 248 -51 105 30 165
                                    127 298 483 44 116 83 203 99 221 16 18 71 49 146 82 140 62 389 185 460 228
                                    28 16 77 46 110 66 33 20 84 44 113 52 33 10 66 29 86 49 l34 34 48 -71 c98
                                    -141 264 -347 351 -434 105 -104 178 -149 227 -140 97 18 132 145 65 230 -12
                                    15 -71 69 -133 120 -145 121 -235 238 -601 781 -155 231 -250 362 -346 482
                                    -57 70 -60 77 -85 190 -28 125 -53 187 -79 197 -23 8 -76 6 -102 -4z m177
                                    -652 c68 -87 325 -464 335 -491 6 -14 -64 -57 -314 -196 -218 -121 -402 -203
                                    -418 -187 -22 22 290 966 319 966 4 0 39 -41 78 -92z"/>
                                                <path d="M16108 7684 c-26 -38 -66 -89 -88 -113 -24 -27 -42 -58 -46 -80 -19
                                    -114 -70 -358 -105 -501 -279 -1154 -834 -2221 -1634 -3140 -143 -165 -481
                                    -503 -656 -656 -1155 -1014 -2576 -1643 -4084 -1808 -313 -34 -440 -41 -815
                                    -41 -375 0 -502 7 -815 41 -1508 165 -2929 794 -4084 1808 -175 153 -509 488
                                    -656 656 -108 124 -261 313 -357 440 l-111 148 -43 -31 c-24 -18 -43 -35 -44
                                    -39 0 -13 211 -290 334 -438 595 -716 1296 -1308 2086 -1763 1219 -702 2584
                                    -1053 3985 -1024 320 7 442 15 734 48 1574 176 3077 853 4258 1916 798 720
                                    1434 1587 1872 2555 260 574 449 1184 566 1830 l15 86 -52 81 c-46 72 -56 81
                                    -83 81 -16 0 -52 3 -80 6 l-50 6 -47 -68z"/>
                                                <path d="M15027 7369 c-39 -36 -58 -61 -62 -83 -52 -322 -72 -426 -126 -641
                                    -269 -1077 -826 -2085 -1590 -2879 -1114 -1157 -2549 -1835 -4164 -1968 -225
                                    -18 -790 -15 -1015 6 -1103 101 -2082 440 -2985 1031 -473 309 -899 677 -1285
                                    1110 l-124 140 -70 17 c-38 10 -71 16 -74 13 -14 -14 307 -372 524 -585 630
                                    -617 1325 -1078 2129 -1412 1351 -561 2889 -657 4310 -268 518 142 1007 339
                                    1450 586 180 100 227 128 372 220 1201 765 2141 1913 2645 3229 169 440 304
                                    944 371 1383 14 91 14 92 -7 92 -31 1 -187 38 -227 54 -10 5 -34 -10 -72 -45z"/>
                                                <path d="M4689 6277 c-43 -28 -79 -98 -79 -153 0 -23 9 -81 20 -128 l21 -86
                                -86 -148 c-277 -472 -411 -655 -584 -794 -237 -190 -482 -211 -578 -48 -18 30
                                -33 62 -33 72 0 9 50 86 111 170 125 171 237 355 285 467 81 185 66 378 -38
                                495 -47 54 -95 81 -160 91 -79 12 -185 -50 -279 -162 -64 -76 -145 -251 -180
                                -388 -87 -343 -19 -772 152 -960 89 -97 177 -136 309 -137 210 -1 367 79 575
                                293 175 181 301 367 451 667 l92 183 16 -88 c9 -48 26 -180 36 -293 34 -355
                                43 -387 110 -414 52 -21 75 -20 128 4 47 21 58 40 98 173 31 102 86 231 199
                                462 96 196 180 345 193 345 5 0 18 -34 30 -75 76 -260 212 -454 370 -528 188
                                -88 492 -11 661 168 99 104 140 242 97 324 l-19 35 -30 -35 c-175 -201 -213
                                -232 -337 -274 -107 -37 -200 -37 -274 -1 -140 68 -262 298 -295 556 -17 125
                                -30 176 -52 196 -18 16 -25 17 -67 6 -117 -31 -224 -147 -297 -322 -29 -70
                                -282 -580 -294 -593 -14 -15 -52 269 -66 500 -21 327 -36 407 -84 433 -32 17
                                -86 11 -122 -13z m-1138 -330 c59 -46 71 -158 30 -277 -28 -79 -110 -227 -205
                                -369 l-70 -106 -9 40 c-5 22 -6 108 -4 190 6 169 32 295 86 409 49 103 123
                                151 172 113z"/>
                                                <path d="M1754 6099 c-96 -7 -283 -40 -374 -66 -30 -8 -146 -43 -257 -78 -123
                                -39 -252 -72 -330 -85 -184 -31 -215 -39 -241 -63 -52 -48 -78 -140 -58 -203
                                4 -14 30 -33 71 -52 35 -17 71 -41 79 -54 16 -25 23 -88 31 -318 6 -139 78
                                -870 91 -912 2 -9 9 -50 13 -92 14 -123 91 -265 164 -303 87 -45 245 10 375
                                129 83 77 183 153 307 233 498 325 892 764 981 1096 23 86 23 218 0 289 -46
                                138 -216 335 -340 395 -92 44 -202 74 -296 81 -41 3 -86 6 -100 8 -14 1 -66
                                -1 -116 -5z m281 -265 c125 -26 208 -76 264 -158 65 -96 78 -174 51 -298 -72
                                -327 -186 -471 -585 -740 -77 -52 -210 -141 -295 -199 -150 -101 -323 -199
                                -352 -199 -19 0 -61 79 -81 150 -9 30 -25 111 -36 180 -20 118 -30 226 -66
                                710 -8 113 -17 237 -21 275 l-5 70 78 27 c43 14 142 49 220 77 303 108 622
                                148 828 105z"/>
                                            </g>
                                        </svg>
                                    </a>
                                    <figcaption>Brasserie Den Anker</figcaption>
                                </figure>
                            </div>
                        </aside>
                    </div>
                </div>
            </footer>
        </div>
        <div id=footer-bottom-wrap>
            <footer class=clearfix id=footer-bottom>
                <div class=content>
                    <nav class="nav horizontal" id=nav-footer>
                        <ul id=menu-footernavigatie-nl class=menu>
                            <li id=menu-item-178 class="menu-item menu-item-type-post_type menu-item-object-page menu-item-178"><a href="https://isense-development.be/contact">Contact</a>
                            </li>
                        </ul>
                    </nav>
                    <p id=about-the-author><span style="font-size: 0.778em; margin-right: -5px;">Designed by</span>
                        <a class=original-media href="https://isense-development.be" rel=external title="Isense-Development">Isense-Development</a></p>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<style scoped>

</style>
